<template>
  <div id="pages">
    <!-- <h2>Orders</h2> -->
    <!-- <a @click="Search"  href="javascript:;">Search</a> -->
    <!-- <router-link to='/sendAccount' class="btn btn-medium btn-primary create-btn">New Order</router-link> -->
    <div class="crumbs">My Daollar > DO</div>
    <div>
      <TabCounted></TabCounted>
    </div>
  </div>
</template>
<script>
import TabCounted from "../middleGround/TabTable/TabCounted";

export default {
  data() {
    return {

    };
  },
  methods: {
    
  },
  mounted () {
    
  },
  components: {
    TabCounted
  }
};
</script>
<style scoped lang="less">
#pages {
  .crumbs {
    font-family: Louis George Cafe;
    font-size: 20px;
    line-height: 23px;
    color: #818E9E;
    margin-bottom: 30px;
  }
  h2 {
    font-family: ParmaPetit;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 27px;
    color: #000000;
    margin-bottom: 30px;
  }
  .create-btn {
    font-family: Louis George Cafe;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    // margin: 0px 10px;
    width: 106px;
    height: 36px;
    margin-bottom: 30px;
    display: block;
    min-width: auto;
  }
}
</style>
