<template>
  <div class="tab-content">
    <div>
      <div>
        <!-- <div class="top"> -->
          <!-- <div class="top-left">
            <div :class="type === 'Active' ? 'active' : ''" @click="getTag('Active')">Active</div>
            <div :class="type !== 'Active' ? 'active' : ''" @click="getTag('Complete')">Complete</div>
          </div> -->
          <!-- <div class="top-right">
             <el-date-picker
              v-model="time"
              type="daterange"
              range-separator="-"
              start-placeholder="Start time"
              end-placeholder="End time"
              >
            </el-date-picker>
          </div> -->
        <!-- </div> -->
        <div class="main-table">
          <el-table :data="tableData">
            <el-table-column
              prop="id"
              label="Number"
              width="100"
              >
            </el-table-column>
            <el-table-column
              label="Date & Time" 
              width="280">
              <template slot-scope="scope">
                <i class="el-icon-time"></i>
                <span style="margin-left: 10px">{{ scope.row.time }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="rocValue"
              label="ROC"
              >
            </el-table-column>
            <el-table-column
              prop="doValue"
              label="DO"
              >
            </el-table-column>
            <el-table-column
              prop="rocDo"
              label="ROC/DO"
              >
            </el-table-column>
            <el-table-column
              label="Action"
              >
              <template slot-scope="scope">
                <a class="btn btn-medium btn-primary search-btn" href="javascript:;"  v-show="isApproveDo" @click="openDialog(scope.row.id)">Redeem</a>
                <a class="btn btn-medium btn-primary search-btn" href="javascript:;" v-show="!isApproveDo" @click="!approveLoading && approveDO()"><i class="el-icon-loading" v-show="approveLoading"></i> Approve DO</a>

                <!-- <i class="el-icon-arrow-right" v-show="type === 'Complete'" @click="openDialog"></i> -->

                <!-- <el-popover trigger="hover" placement="top">
                  <p>姓名: {{ scope.row.name }}</p>
                  <p>住址: {{ scope.row.address }}</p>
                  <div slot="reference" class="name-wrapper">
                    <el-tag size="medium">{{ scope.row.name }}</el-tag>
                  </div>
                </el-popover> -->
              </template>
            </el-table-column>
          </el-table>
          <!-- <div class="tb-header">
            <div style="width:100px;">Number</div>
            <div style="width:500px;">Date & Time</div>
            <div style="width:100px;">ROC</div>
            <div style="width:100px;">DO </div>
            <div style="width:200px;">ROC/DO</div> 
            <div style="width:200px;">Action</div>
          </div>
          <div class="tb-body">
            <div class="tb-body-tr" v-for="item in tableData" :key="item.createdAt">
              <div style="width:100px;">{{item.id}}</div>
              <div style="width:500px">{{item.time}}</div>
              <div style="width:100px;">{{item.rocValue}} </div>
              <div style="width:100px;">{{item.doValue}} </div>
              <div style="width:200px;">{{item.rocDo}}</div> 
              <div style="width:200px;">
                <a class="btn btn-medium btn-primary search-btn" href="javascript:;" @click="openDialog" v-if="type === 'Active'">Redeem</a>
                <i class="el-icon-arrow-right" v-else  @click="openDialog"></i>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <el-dialog
        :title="detailTitle"
        :visible.sync="dialogVisible"
        width="30%"
        :closeOnClickModal="false"
        > 
        <!-- <span>这是一段信息</span> -->
        <div v-if="detailTitle === 'Redeem DO'">
          <div class="tip">
            You are going to redeem DO to get back ROC, Continue?
          </div>
          <div>
            <a class="btn btn-medium btn-primary search-btn continue" href="javascript:;" @click="!tradeLoading && reddem()">
              <i class="el-icon-loading" v-show="tradeLoading"></i> Continue</a>
            <span class="cancel" @click="dialogVisible = false">Cancel</span>
          </div>
        </div>
        <div v-else class="do-detail">
          <ul>
            <li><span>Transaction ID:</span><span>0xff05…bbb9</span></li>
            <li><span>Holder:</span><span>0xff05…bbb9</span></li>
            <li><span>Mint Date & Time:</span><span>11/02/2020 10:43:56 AM</span></li>
            <li><span>Redeem Date & Time:</span><span>11/02/2020 10:43:56 AM</span></li>
            <li><span>ROC:</span><span>600.000</span></li>
            <li><span>DO:</span><span>1,121.12934182</span></li>
            <li><span>ROC/DO</span><span>0.5000</span></li>
          </ul>
        </div>
      </el-dialog>
      <!-- <linkDetail :orderId="orderId" ref="detail" v-else></linkDetail> -->
    </div>
  </div>
</template>
<script>
// import moment from "moment";
// import Search from "./Search.vue";
// import MainTable from "./MainTable.vue";
// import linkDetail from "./linkDetail.vue";
// import { orderList } from "_api/roxe.js";
import BigNumber from 'bignumber.js'
import { dataStatus } from "./index.js";
import {
  getDOOrders,
  BigNum,
  PRddress,
  PRContract,
  DOContract,
  isApproveDo
} from "../../../unitls"
export default {
  components: {
    // Search,
    // MainTable,
    // linkDetail,
  },
  data () {
    return {
      isApproveDo: false,
      time: '',
      current: 1,
      total: 0,
      detailTitle: 'Redeem DO',
      dialogVisible: false,
      type: 'Active',
      address: localStorage.getItem('account'),
      tableData: [],
      indexValue: 0,
      deleteID: 0,
      tradeLoading: false,
      approveLoading: false,
    };
  },
  methods: {
    // 授权DO
    approveDO () {
      const maxApproval = new BigNumber(2).pow(256).minus(1).toFixed();
      this.approveLoading = true
      DOContract().then(data => {
        data.methods.approve(PRddress, maxApproval).send({ from: this.address}).on('receipt', (receipt) => {
          if (receipt.status) {
            this.isApproveDo = true
            this.approveLoading = false
          }
        }).catch((err) => {
          this.approveLoading = false
        })
      })
    },
    // 打开弹窗
    openDialog(id) {
      // if (this.type === 'Active') {
      //   this.detailTitle = 'Redeem DO'
      // } else {
      //   this.detailTitle = 'Details'
      // }
      this.deleteID = id
      this.$nextTick(() => {
        this.dialogVisible = true
      })
    },
    // 切换do列表
    getTag(val) {
      this.type = val
    },
    getOrders() {
      console.log(this.indexValue, 'this.indexValue')
      // alert(this.indexValue)
      getDOOrders(this.address, this.indexValue).then(data => {
        if (data.createdAt != 0) {
          data.id = this.indexValue 
          const time = new Date(Number(data.createdAt) * 1000)
          data.time = String(time).split('(')[0]
          data.rocValue = BigNum(data.rocAmount).dividedBy(new BigNumber(10).pow(18)).toFixed(10)
          data.doValue = BigNum(data.doAmount).dividedBy(new BigNumber(10).pow(18)).toFixed(10)
          data.rocDo = (data.rocValue/data.doValue).toFixed(2)
          this.indexValue = this.indexValue + 1
          if (data.rocValue > 0) {
            this.tableData = this.tableData.concat(data)
          }
          this.getOrders()
        }
      })
    },
    // reddem
    reddem () {
      // 通过roc兑换do
      this.tradeLoading = true
      PRContract().then(data => {
        data.methods.redeemROC(this.deleteID).send({from: this.address}).on('receipt', (receipt) => {
          this.tradeLoading = false
          console.log(receipt, 'receipt')
          if (receipt.status) {
            this.indexValue = 0
            this.tableData = []
            this.getOrders()
            this.dialogVisible = false
          }
        }).catch((err) => {
          this.tradeLoading = false
        })
      })
    }
    
  },
  mounted () {
    // alert(this.address)
    if (this.address) {
      this.getOrders()
      // 判断是否授权do
      isApproveDo(this.address).then(data => {
        console.log(data, 'data')
        if (data > 0) {
          this.isApproveDo = true
        }
      })
    }
    // this.orderListPage();
  }
};
</script>
<style lang="less" scoped>
/deep/ .el-table td {
  height: 60px;
}
/deep/ .el-table thead th > .cell {
  color: #085DA3;
  font-family: Louis George Cafe;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
}

/deep/ .el-table thead th:first-child .cell {
  padding-left: 20px;
  text-align: left;
}

/deep/ .el-table tbody td {
  font-family: Louis George Cafe;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #818E9E;
}
/deep/ .el-table tbody td:first-child {
  padding-left: 20px;
  text-align: left;
}
.tab-content {
  .top {
    display: flex;
    justify-content: space-between;
    .top-left {
      width: 255px;
      height: 46px;
      left: 705px;
      top: 154px;
      background: #FFFFFF;
      /* Gray Light 2 */
      border: 1px solid #E0E0E0;
      box-sizing: border-box;
      // transform: matrix(-1, 0, 0, 1, 0, 0);
      display: flex;
      padding: 2px;
      &>div {
        width:124px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #000;
        cursor: pointer;
      }
      &>div.active {
        background: #1C70BD;
        color: #fff;
      }
    }
  }
  .tab-bg {
    background: #fff;
    padding: 5px 20px;
    min-width: 126px;
    text-align: center;
    font-size: 15px;
    border: solid 2px #fff;
  }
  .tabClick {
    color: #fff;
  }
  .main-table {
    margin-top: 30px;
    border: 1px solid #E0E0E0;
    // padding: 0 40px;
    .tb-header {
      min-height: 60px;
      // display: flex;
      // justify-content: space-between;
      text-align: right;
      line-height: 60px;
      border-bottom: 1px solid #E0E0E0;
      // padding: 0 40px;
      &>div {
        color: #085DA3;
        float: left;
        &:first-child {
          padding-left:20px;
        }
        &:last-child {
          padding-right: 20px;
        }
      }
    }
    .tb-body {
      // padding: 0 40px;
      .tb-body-tr {
        min-height: 60px;
        // display: flex;
        // justify-content: space-between;
        text-align: right;
        line-height: 60px;
        &>div {
          float: left;
          &:first-child {
            padding-left:20px;
          }
          &:last-child {
            padding-right: 20px;
          }
        }
        a {
          width: 80px;
          height: 27px;
          color: #fff;
          background: #2F98EE;
          min-width: 80px;
          line-height: 27px;
          padding: 0;
        }
        i {
          color: #2F98EE;
        }
      }
      .tb-body-tr:nth-child(2n-1) {
        background:#FAFAFA;
      }
      
    }
    //   font-family: @LouisGeorgeCafe;
    //   .text-family {
    //     font-family: @LouisGeorgeCafe !important;
    //   }
    .page {
      text-align: right;
    }
  }

  /deep/ .el-dialog__title {
    font-family: ParmaPetit;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 25px;
    color: #085DA3;
  }
  /deep/ .el-dialog {
    padding-left: 30px;
  }
  .tip {
    font-family: Louis George Cafe;
    font-size: 14px;
    line-height: 16px;
    color: #818E9E;
    margin-bottom: 30px;
  }
  .continue {
    font-family: Louis George Cafe;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
  }

  .cancel {
    margin-left: 30px;
    font-family: Louis George Cafe;
    font-size: 18px;
    line-height: 21px;
    color: #085DA3;

  }
  .do-detail {
    ul {
      li {
        margin-bottom: 20px;
        span {
          font-family: Louis George Cafe;
          font-size: 16px;
          line-height: 19px;
          color: #818E9E;
          &:first-child {
            min-width: 150px;
            display: inline-block;
            margin-right: 20px;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .row-status-width {
    width: 140px;
  }
  .pc-table {
    display: none;
  }
  .phone-table {
    display: block;
  }
}
@media (min-width: 768px) {
  .phone-table {
    display: none;
  }
  .pc-table {
    display: block;
  }
}
</style>
